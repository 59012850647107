//COLOR MODS
$Spot: rgb(5, 193, 157);
$DarkSpot: rgb(20, 96, 81);

$Background: rgba(5, 193, 159, 0.659);
$Background2: rgba(71, 98, 95, 0.815);

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  outline: none;
}
body::-webkit-scrollbar {
  display: none;
}

h2 {
  font-size: 18vw;
  text-transform: uppercase;
  color: $Spot;
  padding-bottom: 5vw;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: url("/assets/img/Background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#Spacing {
  display: flex;
  flex-direction: column;
  gap: 20vw;
  padding: 0 5vw 20vw;
}

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  top: 0;
  height: 12vw;
  width: 100vw;
  padding: 2vw 5vw;
  z-index: 9999999;
  box-shadow: 0px 2px 2px #00000053;

  #CoverUp {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 4;
  }
  a {
    height: 100%;
    z-index: 5;
  }
  img {
    height: 100%;
    z-index: 5;
  }
  #HeaderNav {
    width: 100%;
    // display: flex;
    display: none;

    justify-content: center;
    z-index: 999999;

    ul {
      display: flex;
      justify-content: space-between;
      gap: 2vw;
      text-transform: uppercase;

      li {
        position: relative;
        a {
          font-size: 1vw;
          font-weight: 800;
          position: relative;

          cursor: pointer;
        }
      }
    }
  }

  .hamburger-react {
    display: block;
    color: $Spot;
    fill: $Spot;
    z-index: 5;
    div {
      color: $Spot;
    }
    &[aria-expanded="true"] {
      div {
        color: $Spot;
      }
    }
  }
  #burgerNotActive,
  #burgerActive {
    display: block;
    padding: 0;
    position: fixed;
    top: 0%;
    left: 100%;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    z-index: 4;
    ul {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: 2vw;
      left: 55%;
      width: 45%;
      height: 100%;
      padding-top: 15vw;
      padding-left: 4vw;
      transition: ease-out 1s;
      overflow: hidden;

      background-color: $Background2;
      backdrop-filter: blur(14.5px);
      z-index: 3;
      li {
        position: relative;
        a {
          mix-blend-mode: normal;
          text-transform: uppercase;
          font-size: 6vw;
          color: white;
          font-weight: 800;
          &.active {
            color: $DarkSpot;
            text-decoration: underline;
          }
        }
      }
    }
  }
  #burgerNotActive {
    pointer-events: none;
    ul {
      margin-left: 100%;
    }
  }
  #burgerActive {
    pointer-events: visiblePainted;
    ul {
      margin-left: 0%;
    }
  }

  #MobileLogo {
    display: none;
  }
  #Logo {
    display: none;
  }
  #Title {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 30vw;
    z-index: 1;
    cursor: pointer;
  }
}

#HeroImg {
  figure {
    position: relative;
    width: 100%;
    top: 10vw;
    img {
      width: 100%;
    }
    figcaption {
      width: 100vw;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      article {
        text-align: center;
        h1 {
          color: white;
          font-size: 7vw;
          text-transform: uppercase;
          font-weight: 800;
        }
        h2 {
          color: white;
          font-size: 6vw;
          text-transform: uppercase;
        }
      }
    }
  }
}

#OmMig {
  padding-top: 30vw;
  figure {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 5vw;

    h2 {
      position: absolute;
      top: -14vw;
      left: 0;
      z-index: 5;
    }
    figcaption {
      position: absolute;
      width: 90%;
      left: 0;
      top: 65vw;
      background-color: $Background2;
      padding: 5vw;
      z-index: 4;
      article {
        p {
          width: 100%;
          color: white;
          line-height: 1.5;
          font-size: 4vw;
        }
      }
    }
    #Long {
      width: 90%;
      // height: fit-content;
      filter: drop-shadow(0px 14px 10px #00000053);
      z-index: 3;
    }
    #Short {
      display: none;
      width: 80%;
      // height: fit-content;
    }
  }
}

#Priser {
  padding-top: 35vw;
  article {
    h3 {
      text-transform: uppercase;
      color: $Spot;
      font-size: 5vw;
    }
    p {
      padding-top: 5vw;
      font-size: 4vw;
      line-height: 1.5;
      span {
        text-decoration: underline;
      }
    }
    ul {
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 4vw;
      font-weight: 600;
      font-size: 5vw;
      text-align: center;

      background-color: white;
      border: solid 1px $Background;
      padding: 7vw;
      width: 100%;

      hr {
        border: solid 1px $Spot;
      }
    }
  }
}

#Portrait {
  padding-top: 12vw;
  article {
    h3 {
      text-transform: uppercase;
      color: $Spot;
      font-size: 5vw;
    }
    p {
      padding-top: 5vw;
      font-size: 4vw;
      line-height: 1.5;
      span {
        text-decoration: underline;
      }
    }
  }
  figure{
    padding-top: 1vw;
    display: flex;
    flex-wrap: wrap;
    gap: 3vw;
  }
  img {
    width: 100%;
    filter: drop-shadow(0px 14px 10px #00000053);
  }
}

#Galleri {
  padding-top: 12vw;
  .Div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4vw;
    padding-bottom: 10vw;
  }
  .form-control {
    font-size: 5vw;
    line-height: 1.1;
    display: flex;
    gap: 2vw;
  }
  input[type="checkbox"] {
    appearance: none;
    background-color: white;
    margin: 0;
    font: inherit;
    color: black;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $Spot;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10vw;

    hr {
      width: 70vw;
      border: 2px solid $Background;
      margin: auto;
    }
    #HideSpam {
      display: none;
    }
    figure {
      img {
        width: 100%;
        filter: drop-shadow(0px 14px 10px #00000053);
      }
      figcaption {
        h5 {
          color: $Spot;
          font-size: 6vw;
          text-align: center;
          padding-top: 4vw;
          text-transform: uppercase;
        }
        p {
          text-align: center;
          font-size: 4vw;
          padding-top: 2vw;
        }
      }
    }
  }
}

#Gift {
  padding-top: 12vw;
  figure{
    width: 100%;
    img {
      width: 100%;
      margin: auto;
      filter: drop-shadow(0px 14px 10px #00000053);
    }
  }
  p {
    padding-top: 5vw;
    font-size: 5vw;
    line-height: 1.5;
    span {
      text-decoration: underline;
    }
  }
}


#Kontakt {
  background-color: white;
  padding: 5vw 5vw 20vw;

  article {
    padding: 0 5vw 5vw;
    p {
      font-size: 4vw;
      line-height: 1.5;
      font-style: italic;
    }
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    #FormWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6vw;
      padding-bottom: 5vw;
    }

    .InputWrapper,
    .InputWrapperError,
    .TextareaError {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;

      &.InputWrapperError,
      &.TextareaError {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
        input,
        textarea {
          border: solid 3px red;
        }
      }

      input,
      textarea {
        width: 100%;
        padding: 3vw;
        border-radius: 1vw;
        resize: none;
        outline: 3px solid $Background2;
        border: none;
        font-size: 3.5vw;

        &:focus {
          outline: 3px solid $Spot;
        }
        &:hover {
          outline: 3px solid $Spot;
        }
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      /* Firefox */
      input[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield;
      }
    }
    span {
      padding: 0;
      line-height: 2;
      color: red;
      font-size: 4vw;
      font-weight: 600;
    }

    button {
      background-color: $Spot;
      margin: 0;
      padding: 4vw 0vw;
      border-radius: 4vw;
      border: none;
      width: 30vw;
      color: white;
      font-size: 5vw;
      font-family: industry-inc-base, sans-serif;
      font-weight: 400;
      font-style: normal;

      &:hover {
        background-color: $DarkSpot;
      }
    }

    .noShow {
      display: none;
      margin-top: 5vw;
    }
    .Show {
      bottom: -12vw;
      position: absolute;

      &.Good {
        font-size: 8vw;
        color: $Spot;
      }
      &.Bad {
        font-size: 5vw;
        color: red;
      }
    }
  }
}

footer {
  padding: 2vw 5vw 2vw;
  background-color: $Background;

  p {
    text-align: center;
    a {
      cursor: pointer;
      font-style: italic;
      color: white;
    }
  }
}

/* Styles for medium screens (tablets) */
@media (min-width: 768px) {
  h2 {
    font-size: 10vw;
    text-transform: uppercase;
    color: $Spot;
    padding-bottom: 2vw;
  }

  #Spacing {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    padding: 0vw 5vw 10vw;
  }

  header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    top: 0;
    height: 10vw;
    width: 100vw;
    padding: 3vw 5vw;
    z-index: 9999999;

    #CoverUp {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      z-index: 4;
    }
    a {
      height: 100%;
      z-index: 5;
    }
    img {
      height: 100%;
      z-index: 5;
    }
    #HeaderNav {
      width: auto;
      display: flex;

      justify-content: center;
      z-index: 999999;

      ul {
        display: flex;
        justify-content: space-between;
        gap: 2vw;
        text-transform: uppercase;

        li {
          position: relative;
          a {
            font-size: 2.5vw;
            font-weight: 800;
            position: relative;
            color: $Spot;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .hamburger-react {
      display: none;
    }
    #burgerNotActive,
    #burgerActive {
      display: none;
    }
  }

  #OmMig {
    position: relative;
    top: -10vw;
    padding-top: 0vw;
    figure {
      padding-top: 13vw;
      position: relative;
      display: flex;
      justify-content: center;
      gap: 0vw;

      h2 {
        display: none;
      }
      figcaption {
        position: relative;
        width: 40%;
        left: 0vw;
        top: 0vw;
        background-color: $Background2;
        padding: 2vw;
        z-index: 4;
        article {
          p {
            width: 100%;
            color: white;
            line-height: 1.5;
            font-size: 1.5vw;
          }
        }
      }
      #Long {
        display: none;
      }
      #Short {
        display: block;
        width: 40%;
        // height: fit-content;
        filter: drop-shadow(0px 14px 10px #00000053);
      }
    }
  }

  #Priser {
    padding-top: 10vw;
    padding-bottom: 10vw;
    position: relative;

    article {
      margin: auto;
      h3 {
        font-size: 3vw;
        margin: auto;
        width: 70vw;
      }
      p {
        text-align: center;
        width: 70vw;
        padding-top: 1vw;
        font-size: 2vw;
        line-height: 1.5;
        margin: auto;
        span {
          text-decoration: underline;
        }
      }
      ul {
        display: flex;
        flex-direction: row;
        gap: 4vw;
        font-weight: 600;
        font-size: 2vw;
        text-align: center;
        justify-content: center;

        background-color: white;
        border: solid 1px $Background;
        padding: 3vw;
        width: 70vw;

        hr {
          border: solid 1px $Spot;
        }
      }
    }
  }

  #Portrait {
    padding-top: 10vw;
    padding-bottom: 10vw;
    position: relative;

    article {
      margin: auto;
      h3 {
        font-size: 3vw;
        margin: 0;
        width: 70vw;
      }
      p {
        text-align: center;
        width: 70vw;
        padding-top: 1vw;
        font-size: 1.5vw;
        line-height: 1.5;
        margin: auto;
        span {
          text-decoration: underline;
        }
      }
    }
    figure {
      padding-top: 1vw;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 2vw;
      img {
        width: 48.5%;
      }
    }
  }

  #Galleri {
    padding-top: 10vw;
    #Modal {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.646);
      top: 0vw;
      width: 100vw;
      height: 100vh;
      left: 0vw;
      z-index: 9999999;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: hidden;

      .HighlightImgW {
        height: 50%;
      }
      .HighlightImgH {
        height: auto;
        width: 80%;
      }

      $button-size: 40px;
      $close-width: ($button-size / 10);

      .close-button {
        top: 2vw;
        right: 2vw;
        position: absolute;
        width: $button-size;
        height: $button-size;
        overflow: hidden;
        cursor: pointer;
        > div {
          position: relative;
        }
        &-block {
          width: 40px;
          height: 20px;
          position: relative;
          overflow: hidden;
          &:before,
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(55% - 4px);
            display: block;
            width: 4px;
            height: 25px;
            transform-origin: bottom center;
            background: $Spot;
            transition: all ease-out 280ms;
          }
          &:last-of-type {
            transform: rotate(180deg);
          }
        }
        .in {
          .close-button-block {
            &:before {
              transition-delay: 280ms;
              transform: translateX(20px) translateY(-20px) rotate(45deg);
            }
            &:after {
              transition-delay: 280ms;
              transform: translateX(-22px) translateY(-22px) rotate(-45deg);
            }
          }
        }
        .out {
          position: absolute;
          top: 0;
          left: 0;
          .close-button-block {
            &:before {
              transform: translateX(-5px) translateY(5px) rotate(45deg);
            }
            &:after {
              transform: translateX(5px) translateY(5px) rotate(-45deg);
            }
          }
        }
        &:hover {
          .in {
            .close-button-block {
              &:before {
                transform: translateX(-5px) translateY(5px) rotate(45deg);
              }
              &:after {
                transform: translateX(5px) translateY(5px) rotate(-45deg);
              }
            }
          }
          .out {
            .close-button-block {
              &:before {
                transform: translateX(-20px) translateY(20px) rotate(45deg);
              }
              &:after {
                transform: translateX(20px) translateY(20px) rotate(-45deg);
              }
            }
          }
        }
      }
    }

    .Div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 2vw;
      padding-bottom: 2vw;
    }
    .form-control {
      font-size: 2vw;
      line-height: 1.1;
      display: flex;
      gap: 1vw;
    }
    input[type="checkbox"] {
      appearance: none;
      background-color: white;
      margin: 0;
      font: inherit;
      color: black;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: $Spot;
    }
    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }

    section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 3vw;

      hr {
        width: 70vw;
        border: 2px solid $Background;
        margin: auto;
        display: none;
      }
      #HideSpam {
        display: none;
      }
      figure {
        .GallaryImg {
          object-fit: cover; /* Zoom the image to cover the square */
          height: 30vw;
          width: 100%;
          filter: drop-shadow(0px 14px 10px #00000053);
          cursor: pointer;
          transition: 0.5s;

          &:hover {
            transform: scale(105%);
          }
        }
        figcaption {
          h5 {
            color: $Spot;
            font-size: 1.8vw;
            text-align: center;
            padding-top: 1vw;
          }
          p {
            text-align: center;
            font-size: 1.5vw;
            padding-top: 0.5vw;
          }
        }
      }
    }
  }

  #Gift {
    padding-top: 12vw;
    figure{
      width: 100%;
      img {
        width: 100%;
        margin: auto;
        filter: drop-shadow(0px 14px 10px #00000053);
      }
    }
    p {
      padding-top: 5vw;
      font-size: 3vw;
      line-height: 1.5;
      span {
        text-decoration: underline;
      }
    }
  }  

  #Kontakt {
    background-color: white;
    padding: 12vw 5vw 20vw;

    article {
      padding: 0 5vw 5vw;
      p {
        font-size: 2.5vw;
        line-height: 1.5;
        font-style: italic;
      }
    }

    form {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      #FormWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6vw;
        padding-bottom: 5vw;
      }

      .InputWrapper,
      .InputWrapperError,
      .TextareaError {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;

        &.InputWrapperError,
        &.TextareaError {
          display: flex;
          flex-direction: column;
          align-items: start;
          width: 100%;
          input,
          textarea {
            border: solid 3px red;
          }
        }

        input,
        textarea {
          width: 100%;
          padding: 3vw;
          border-radius: 1vw;
          resize: none;
          outline: 3px solid $Background2;
          border: none;
          font-size: 3.5vw;

          &:focus {
            outline: 3px solid $Spot;
          }
          &:hover {
            outline: 3px solid $Spot;
          }
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        /* Firefox */
        input[type="number"] {
          appearance: textfield;
          -moz-appearance: textfield;
        }
      }
      span {
        padding: 0;
        line-height: 2;
        color: red;
        font-size: 2.5vw;
        font-weight: 600;
      }

      button {
        background-color: $Spot;
        margin: 0;
        padding: 2vw 0vw;
        border-radius: 4vw;
        border: none;
        width: 20vw;
        color: white;
        font-size: 3vw;
        font-family: industry-inc-base, sans-serif;
        font-weight: 400;
        font-style: normal;

        &:hover {
          background-color: $DarkSpot;
        }
      }

      .noShow {
        display: none;
        margin-top: 5vw;
      }
      .Show {
        bottom: -10vw;
        position: absolute;

        &.Good {
          font-size: 5vw;
          color: $Spot;
        }
        &.Bad {
          font-size: 3vw;
          color: red;
        }
      }
    }
  }
}

/* Styles for large screens (desktops) */
@media (min-width: 1025px) {
  h2 {
    font-size: 4vw;
    text-transform: uppercase;
    color: $Spot;
    padding-bottom: 5vw;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    background-image: url("/assets/img/Background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  #Spacing {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    padding: 0vw 5vw 10vw;
  }

  header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    top: 0;
    height: 5vw;
    width: 100vw;
    padding: 1vw 5vw;
    z-index: 9999999;

    #CoverUp {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      z-index: 4;
    }
    a {
      height: 100%;
      z-index: 5;
    }
    img {
      height: 100%;
      z-index: 5;
    }
    #HeaderNav {
      width: auto;
      display: flex;

      justify-content: center;
      z-index: 999999;

      ul {
        display: flex;
        justify-content: space-between;
        gap: 2vw;
        text-transform: uppercase;

        li {
          position: relative;
          a {
            font-size: 1vw;
            font-weight: 800;
            position: relative;
            color: $Spot;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .hamburger-react {
      display: none;
    }
    #burgerNotActive,
    #burgerActive {
      display: none;
    }
  }

  #HeroImg {
    height: 30vw;
    figure {
      top: -10vw;
      img {
        width: 100%;
      }
      figcaption {
        width: 100vw;
        position: absolute;
        top: 65%;
        transform: translateY(-50%);

        article {
          text-align: center;
          h1 {
            color: white;
            font-size: 5vw;
            text-transform: uppercase;
            font-weight: 800;
          }
          h2 {
            color: white;
            font-size: 3vw;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  #OmMig {
    top: 0vw;
    padding-top: 0vw;
    figure {
      padding-top: 10vw;
      position: relative;
      display: flex;
      justify-content: center;
      gap: 0vw;

      h2 {
        position: absolute;
        top: 5vw;
        left: 15vw;
        z-index: 5;
        display: none;
      }
      figcaption {
        position: relative;
        width: 33%;
        left: 0vw;
        top: 0vw;
        background-color: $Background2;
        padding: 2vw;
        z-index: 4;
        article {
          p {
            width: 100%;
            color: white;
            line-height: 1.5;
            font-size: 1.2vw;
          }
        }
      }
      #Long {
        display: none;
      }
      #Short {
        display: block;
        width: 33%;
        // height: fit-content;
        filter: drop-shadow(0px 14px 10px #00000053);
      }
    }
  }

  #Priser {
    padding-top: 15vw;
    padding-bottom: 0;
    position: relative;

    h2 {
      position: absolute;
      top: 8vw;
      left: 15vw;
      z-index: 5;
    }

    article {
      margin: auto;
      h3 {
        font-size: 1.5vw;
        margin: auto;
        width: 60vw;
      }

      p {
        text-align: center;
        width: 50vw;
        padding-top: 1vw;
        font-size: 1vw;
        line-height: 1.5;
        margin: auto;
        span {
          text-decoration: underline;
        }
      }
      ul {
        display: flex;
        flex-direction: row;
        gap: 4vw;
        font-weight: 600;
        font-size: 2vw;
        text-align: center;

        background-color: white;
        border: solid 1px $Background;
        padding: 3vw;
        width: 60vw;

        hr {
          border: solid 1px $Spot;
        }
      }
    }
  }

  #Portrait {
    padding-top: 15vw;
    padding-bottom: 0;
    position: relative;

    h2 {
      position: absolute;
      top: 8vw;
      left: 15vw;
      z-index: 5;
    }

    article {
      margin: auto;
      h3 {
        font-size: 1.5vw;
        margin: auto;
        width: 60vw;
      }

      p {
        text-align: center;
        width: 50vw;
        padding-top: 1vw;
        font-size: 1vw;
        line-height: 1.5;
        margin: auto;
        span {
          text-decoration: underline;
        }
      }
    }
    figure{
      padding-top: 1vw;
      display: flex;
      gap: 2%;

      img{
        width: 23.5%;
      }
    }
  }

  #Galleri {
    padding-top: 10vw;
    #Modal {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.646);
      top: 0vw;
      width: 100vw;
      height: 100vh;
      left: 0vw;
      z-index: 9999999;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: hidden;

      .HighlightImgW {
        height: 70%;
      }
      .HighlightImgH {
        height: 90%;
        width: auto;
      }

      $button-size: 40px;
      $close-width: ($button-size / 10);

      .close-button {
        top: 2vw;
        right: 2vw;
        position: absolute;
        width: $button-size;
        height: $button-size;
        overflow: hidden;
        cursor: pointer;
        > div {
          position: relative;
        }
        &-block {
          width: 40px;
          height: 20px;
          position: relative;
          overflow: hidden;
          &:before,
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(55% - 4px);
            display: block;
            width: 4px;
            height: 25px;
            transform-origin: bottom center;
            background: $Spot;
            transition: all ease-out 280ms;
          }
          &:last-of-type {
            transform: rotate(180deg);
          }
        }
        .in {
          .close-button-block {
            &:before {
              transition-delay: 280ms;
              transform: translateX(20px) translateY(-20px) rotate(45deg);
            }
            &:after {
              transition-delay: 280ms;
              transform: translateX(-22px) translateY(-22px) rotate(-45deg);
            }
          }
        }
        .out {
          position: absolute;
          top: 0;
          left: 0;
          .close-button-block {
            &:before {
              transform: translateX(-5px) translateY(5px) rotate(45deg);
            }
            &:after {
              transform: translateX(5px) translateY(5px) rotate(-45deg);
            }
          }
        }
        &:hover {
          .in {
            .close-button-block {
              &:before {
                transform: translateX(-5px) translateY(5px) rotate(45deg);
              }
              &:after {
                transform: translateX(5px) translateY(5px) rotate(-45deg);
              }
            }
          }
          .out {
            .close-button-block {
              &:before {
                transform: translateX(-20px) translateY(20px) rotate(45deg);
              }
              &:after {
                transform: translateX(20px) translateY(20px) rotate(-45deg);
              }
            }
          }
        }
      }
    }

    h2 {
      padding-left: 15vw;
      z-index: 5;
    }

    .Div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 2vw;
      padding-bottom: 2vw;
    }
    .form-control {
      font-size: 2vw;
      line-height: 1.1;
      display: flex;
      gap: 1vw;
    }
    input[type="checkbox"] {
      appearance: none;
      background-color: white;
      margin: 0;
      font: inherit;
      color: black;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: $Spot;
    }
    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }

    section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 2vw;

      hr {
        width: 70vw;
        border: 2px solid $Background;
        margin: auto;
        display: none;
      }
      #HideSpam {
        display: none;
      }
      figure {
        .GallaryImg {
          object-fit: cover; /* Zoom the image to cover the square */
          height: 20vw;
          width: 100%;
          filter: drop-shadow(0px 14px 10px #00000053);
          cursor: pointer;
          transition: 0.5s;

          &:hover {
            transform: scale(105%);
          }
        }
        figcaption {
          h5 {
            color: $Spot;
            font-size: 1.5vw;
            text-align: center;
            padding-top: 1vw;
          }
          p {
            text-align: center;
            font-size: 0.8vw;
            padding-top: 0.5vw;
          }
        }
      }
    }
  }

  #Gift {
    position: relative;
    padding-top: 15vw;
    h2 {
      position: absolute;
      top: 8vw;
      left: 15vw;
      z-index: 5;
    }

    figure{
      width: 100%;
      margin: auto;
      img {
        display: block;
        width: 60%;
        margin: auto;
        filter: drop-shadow(0px 14px 10px #00000053);
      }
    }
    article{
      display: flex;
      width: 60%;
      margin: auto;
    }
    p {
      text-align: center;
      padding-top: 2vw;
      font-size: 1.5vw;
      line-height: 1.5;
      span {
        text-decoration: underline;
      }
    }
  }  

  #Kontakt {
    background-color: white;
    padding: 7vw 5vw 7vw;

    h2 {
      text-align: center;
      padding-bottom: 2vw;
    }

    article {
      padding: 0 0vw 2vw;
      width: 50vw;
      margin: auto;
      p {
        font-size: 1vw;
        line-height: 1;
        font-style: italic;
      }
    }

    form {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin: auto;

      #FormWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2vw;
        padding-bottom: 2vw;
      }

      .InputWrapper,
      .InputWrapperError,
      .TextareaError {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;

        &.InputWrapperError,
        &.TextareaError {
          display: flex;
          flex-direction: column;
          align-items: start;
          width: 100%;
          input,
          textarea {
            border: solid 3px red;
          }
        }

        input,
        textarea {
          width: 100%;
          padding: 1vw;
          border-radius: 1vw;
          resize: none;
          outline: 3px solid $Background2;
          border: none;
          font-size: 1vw;

          &:focus {
            outline: 3px solid $Spot;
          }
          &:hover {
            outline: 3px solid $Spot;
          }
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        /* Firefox */
        input[type="number"] {
          appearance: textfield;
          -moz-appearance: textfield;
        }
      }
      span {
        padding: 0;
        line-height: 2;
        color: red;
        font-size: 1vw;
        font-weight: 600;
      }

      button {
        background-color: $Spot;
        margin: 0;
        padding: 1vw 0vw;
        border-radius: 4vw;
        border: none;
        width: 15vw;
        color: white;
        font-size: 2vw;
        font-family: industry-inc-base, sans-serif;
        font-weight: 400;
        font-style: normal;

        &:hover {
          background-color: $DarkSpot;
        }
      }

      .noShow {
        display: none;
        margin-top: 5vw;
      }
      .Show {
        bottom: -5vw;
        position: absolute;

        &.Good {
          font-size: 1.5vw;
          color: $Spot;
        }
        &.Bad {
          font-size: 1.5vw;
          color: red;
        }
      }
    }
  }

  footer {
    padding: 1vw 5vw 1vw;
    background-color: $Background;

    p {
      text-align: center;
      font-size: 1vw;
      a {
        cursor: pointer;
        font-style: italic;
        color: white;
      }
    }
  }
}
